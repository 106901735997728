
import store from "@/store/index";
import { WorkstationController, DepartmentController, MachineController, WorkareaController } from '@/controller';
import { IconButton, Button, SelectSingle, Card, Switch } from '@/ui/index';
import { defineComponent } from 'vue';

export default defineComponent({
    name: "WorkstationstationForm",
    components: { IconButton, Button, SelectSingle, Card, Switch },
    data() {
        return {
            store,
            editMode: false,
            form:{
                uid: 0,
                title: "",
                department: 0,
                workarea: 0,
                machine: 0,
                uuidWorkstation: '',
                nfcChipReader: false
            },
            departments: [] as Array<any>,
            workareas: [] as Array<any>,
            machines: [] as Array<any>
        }
    },
    computed:{
        getProfileImageSrc() {
            return "";
        }
    },
    created() {
        if (this.$route.params.id && this.$route.params.id != "0" ) {
            this.editMode = true;
            this.getWorkstation(this.$route.params.id);
        } else {
            this.editMode = false;
        }
        this.getDepartments();
        this.getWorkareas();
        this.getMachines();
    },
    methods:{
        handleGoBack() {
            this.$router.go(-1);
        },
        handleSubmitPrevent(){
            if (this.editMode) {
                this.saveWorkstation();
            } else {
                this.createWorkstation();
            }
        },
        async getWorkstation(uid:any) {
            const res = await WorkstationController.getWorkstationById(uid);
            if (!res.error) {
                console.log('res.owkrstation', res.workstation)
                this.form = res.workstation
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        },
        async getDepartments() {
            const res = await DepartmentController.fetchDepartments();
            if(!res.error){
                this.departments = res.items;
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }        },
        async getMachines() {
            const res = await MachineController.fetchMachines();
            if(!res.error){
                this.machines = res.items;
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        },
        async getWorkareas() {
            const res = await WorkareaController.fetchWorkareas();
            if(!res.error){
                this.workareas = res.items;
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }        },
        async createWorkstation() {
            const res = await WorkstationController.createWorkstation(this.form);
            if (!res.error) {
                this.$notify(this.$t("success.workstationCreated"), { position: "top" });
                this.$router.push("/administration/workstations");
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        },
        async saveWorkstation() {
            const res = await WorkstationController.updateWorkstation(this.form);
            if (!res.error) {
                this.form = res.workstation;
                this.$notify(this.$t("success.workstationUpdated"), { position: "top" });
                this.$router.push("/administration/workstations");
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        }
    }
})
